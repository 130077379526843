<template>
	<div class="waybillReview_box">
		<jy-query ref="checkForm" :model="formData">
			<jy-query-item prop="routeId" label="线路组织:">
				<div @click="selectInstitutions">
					<!-- <el-input placeholder="请选择" v-model="formData.routeName"></el-input> -->
					<el-select v-model="formData.routeId" placeholder="请选择线路">
						<el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId"> </el-option>
					</el-select>
				</div>
			</jy-query-item>
			<jy-query-item label="车牌号:" prop="vehicleNo">
				<el-input v-model="formData.vehicleNo" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item label="司机:" prop="driverName">
				<el-input v-model="formData.driverName" placeholder="请输入"></el-input>
			</jy-query-item>
			<jy-query-item label="审核状态:" prop="auditState">
				<el-select v-model="formData.auditState" placeholder="请选择">
					<el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="跨线路:" prop="isScheduleShift">
				<el-select v-model="formData.isScheduleShift" placeholder="请选择" filterable>
					<el-option v-for="item in ScheduleShiftList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="日期:" prop="date">
				<el-date-picker
					v-model="formData.date"
					type="date"
					placeholder="选择日期"
					value-format="yyyy-MM-dd"
					:picker-options="pickerOptions"
				></el-date-picker>
			</jy-query-item>
			<template slot="search">
				<el-button v-if="btnexist('list') || true" type="primary" @click="oncheck">查询</el-button>
				<el-button v-if="btnexist('reset') || true" type="primary" plain @click="resetForm('checkForm')">重置</el-button>
			</template>
		</jy-query>
		<el-tabs v-model="activeName" @tab-click="tabClick">
			<!-- <el-tab-pane
                :label="one.label"
                :name="one.name"
                v-for="one in tabList"
                v-bind:key="one.name"
            >
                <keep-alive>
                    <router-view :ref="one.name" />
                </keep-alive>
            </el-tab-pane>-->
			<el-tab-pane label="营运班次补录审核" name="waybillCollectionReview">
				<way-bill ref="waybillCollectionReview" :formData="formData"></way-bill>
			</el-tab-pane>
			<el-tab-pane label="工作时长补录审核" name="workTimeCollectionReview">
				<work-time ref="workTimeCollectionReview" :formData="formData"></work-time>
			</el-tab-pane>
			<el-tab-pane label="非营运补录审核" name="noOperatingDivisionsCollectionReview">
				<no-operating ref="noOperatingDivisionsCollectionReview" :formData="formData"></no-operating>
			</el-tab-pane>
			<el-tab-pane label="定点专线补录审核" name="fixedLineCollectionReview">
				<fixed-line ref="fixedLineCollectionReview" :formData="formData"></fixed-line>
			</el-tab-pane>
		</el-tabs>
		<institutions-tree
			ref="institutions"
			@addInstitutions="addInstitutions"
			url="/waybillOperate/getTree"
			title="选择线路组织"
			:defaultProps="props"
		></institutions-tree>
	</div>
</template>
<script>
const status = [
	{
		value: "0",
		label: "全部"
	},
	{
		value: "1",
		label: "已审核"
	},
	{
		value: "2",
		label: "未审核"
	}
];
// children: [{
//                 path: 'waybillCollectionReview',
//                 name: 'waybillCollectionReview',
//                 component: () => import('./views/admin/waybillManagement/waybillReview/waybillCollectionReview.vue')
//             }, {
//                 path: 'workTimeCollectionReview',
//                 name: 'workTimeCollectionReview',
//                 component: () => import('./views/admin/waybillManagement/waybillReview/workTimeCollectionReview.vue')
//             }, {
//                 path: 'noOperatingDivisionsCollectionReview',
//                 name: 'noOperatingDivisionsCollectionReview',
//                 component: () => import('./views/admin/waybillManagement/waybillReview/noOperatingDivisionsCollectionReview.vue')
//             }, {
//                 path: 'fixedLineCollectionReview',
//                 name: 'fixedLineCollectionReview',
//                 component: () => import('./views/admin/waybillManagement/waybillReview/fixedLineCollectionReview.vue')
//             }]
import fixedLine from "./fixedLineCollectionReview.vue";
import noOperating from "./noOperatingDivisionsCollectionReview.vue";
import wayBill from "./waybillCollectionReview.vue";
import workTime from "./workTimeCollectionReview.vue";

import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";

import { btnMixins } from "@/common/js/button.mixin";

export default {
	data() {
		return {
			props: {
				children: "children",
				label: "text"
			},
			plateList: [],
			driverList: [],
			ScheduleShiftList: [
				{
					value: "",
					label: "全部"
				},
				{
					value: "1",
					label: "是"
				},
				{
					value: "0",
					label: "否"
				}
			],
			formData: {
				type: 1,
				routeId: "",
				routeName: "",
				date: "",
				vehicleNo: "",
				driverName: "",
				checked: false,
				isScheduleShift: "",
				auditState: "0"
			},
			activeName: "waybillCollectionReview",
			oldActiveName: "waybillCollectionReview",
			tabList: [],
			status,
			pickerOptions: {
				cellClassName(date) {
					//已完成行车，但未进行备注的日期
					if (date.Format("yyyy-MM-dd") == "1082-10-10") {
						return "changered";
					}
				}
			},
			routeOptions: [],

			btnRoute: "/admin/waybillManagement/waybillReview"
		};
	},
	mixins: [btnMixins],
	components: {
		institutionsTree,
		fixedLine,
		noOperating,
		wayBill,
		workTime
	},
	created() {
		this.getDate();
		this.setActeiveName();
		this.getRouteList();
	},
	activated() {
		this.setActeiveName();
		this.getRouteList();
	},
	watch: {
		$route: function () {
			this.setActeiveName();
		},
		"formData.routeName": function (val, oldVal) {
			if (val != oldVal) {
				if (this.formData.routeName == "") {
					this.formData.routeId = "";
				}
			}
		},
		activeName: function (val) {
			if (this.$route.name == "waybillReview" || this.$route.name == "waybillReviewDefault") {
				this.oldActiveName = val;
			}
		}
	},
	methods: {
		getRouteList() {
			let url = "/baseinforoute/queryCurrentAuthorityRoute";
			this.$http.post(url).then(({ detail }) => {
				this.routeOptions = detail;
			});
		},
		// 查询默认当天
		getDate() {
			var date = new Date();
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var day = date.getDate();
			if (month < 10) {
				month = "0" + month;
			}
			if (day < 10) {
				day = "0" + day;
			}
			var nowDate = year + "-" + month + "-" + day;
			this.formData.date = nowDate;
		},
		setActeiveName() {
			let route = this.$route;
			if (route.query && route.query.type) {
				this.activeName = route.query.type;
			} else {
				this.activeName = this.oldActiveName;
			}
		},
		// 组织
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		addInstitutions(data) {
			this.formData.routeId = data.id;
			this.formData.routeName = data.text;
			// this.oncheck()
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		oncheck() {
			let ref = this.$refs[this.activeName];
			ref.getList();
		},
		tabClick(e) {
			let url = "/admin/waybillManagement/waybillReview";
			this.$router.replace({ path: url, query: { type: e.name } });
		}
	}
};
</script>
<style lang="scss">
.changered span {
	color: red;
}
.waybillReview_box {
	.el-table {
		.el-table__fixed {
			// 左固定列
			height: auto !important;
			bottom: 16px; // 改为自动高度后，设置与父容器的底部距离，高度会动态改变，值可以设置比滚动条的高度稍微大一些
		}
		.el-table__fixed-right {
			// 右固定列
			height: auto !important;
			bottom: 16px; // 改为自动高度后，设置与父容器的底部距离，高度会动态改变，值可以设置比滚动条的高度稍微大一些
		}
	}
}
</style>
